@font-face {
    font-family: 'tt_rounds';
    src: local('tt_rounds'), url(./fonts/TT_Rounds-Regular-webfont.woff2) format('woff2');
}

:root {
    --mdc-theme-primary: #adda43;
    --mdc-theme-error: #cf228b;
    --mdc-typography-font-family: "tt_rounds","Helvetica Neue",Helvetica,Arial,sans-serif;
    --mdc-typography-body2-font-family: "tt_rounds","Helvetica Neue",Helvetica,Arial,sans-serif;
    --mdc-typography-subtitle1-font-family: "tt_rounds","Helvetica Neue",Helvetica,Arial,sans-serif;
}
body {
    max-width: 930px;
    letter-spacing: 0!important;
    font-family: "tt_rounds","Helvetica Neue",Helvetica,Arial,sans-serif;
    color: #4d4d4f;
}
label {
    width: 100%;
}
p {
    line-height: 1rem;
}

.cg-row {
    padding: 10px 0 0 0;
}
.cg-row-inner {
    padding: 10px 0 0 0;
}

.error-info {
    display: none;
    color: #cf228b;
    line-height: 1.3;
    margin: 5px 0 5px;
}
.mdc-text-field+.mdc-text-field-helper-line {
    padding-left: 0;
}
.mdc-notched-outline.mdc-notched-outline--upgraded .mdc-notched-outline__leading,
.mdc-notched-outline.mdc-notched-outline--upgraded .mdc-notched-outline__trailing,
.mdc-text-field--outlined .mdc-notched-outline .mdc-notched-outline__leading,
.mdc-text-field--outlined .mdc-notched-outline .mdc-notched-outline__trailing,
.mdc-button {
    border-radius: 0;
}
.mdc-button {
    width: 215px;
    height: 55px;
    text-transform: none;
    font-size: 1rem;
}
.mdc-button--unelevated{
    border-radius: 100px;
}
.mdc-text-field--focused:not(.mdc-text-field--disabled) .mdc-floating-label {
    color: #888;
}

.mdc-text-field--outlined.mdc-text-field--disabled {
    background-color: #dcdcdc;
}

.grecaptcha-badge { 
    box-shadow: none !important; 
}

/* STEP 2 */
.step2 {
    display: flex;
    margin: 0 -10px !important;
    gap: 0;
    flex-wrap: wrap-reverse;
}

.step2 .item {
    margin: 0 10px !important;
}

.step2 .form-container {
    width: 435px;
}
.pesel-container {
    display: flex;
    flex-wrap: wrap;
    width: 435px;
    justify-content: space-between;
}
.pesel-number {
    margin: 0;
}
.pesel-number > div {
    text-align: center;
    font-size: .8rem;
}
.pesel-input {
    width: 34px;
    text-align: center;
    padding-left: 8px;
    padding-right: 8px;
}

.step2 .info-container {
    flex: 1 1 auto;
}
.agreement-info-box {
    background-color: #ededed;
    color: #4d4d4f;
    border-radius: 10px;
    padding: 10px 20px 10px 20px;
    width: 100%;
}
.agreement-info-box .small {
    font-size: 0.6rem;
}

.pbl_header
{
    color: #00ACE9;
}

.pbl_header em{
    font-size: xx-large;
    font-weight: bolder;
    font-style: normal;
}